
import LogosCarousel from '~/components/LogosCarousel.vue'
export default {
  name: 'PressSlice',
  components: { LogosCarousel },
  props: ['slice'],
  data() {
    return {
      items: this.slice.items.map(item => {
        return {
          link: item.press_link ? item.press_link.url : false,
          url: item.press_logo.url,
          alt: item.press_logo.alt,
          description: item.press_description
        }
      })
    }
  }
}
